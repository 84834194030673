<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item  :to="{path: '/gqdj?hzy_id=9'}">外贸营销平台工具</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item>供求对接</el-breadcrumb-item> -->
                        <el-breadcrumb-item  :to="{path: '/gqdj?hzy_id=9'}">发布供求易货信息</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="details-text">发布供求易货信息</div>
                    <div class="biaodan-wrap">
                        <div class="biaodan">
                            <div class="baiodan-p">产品名称：</div>
                            <el-input v-model="form.name"></el-input>
                        </div>
                        <div class="biaodan">
                            <div class="baiodan-p">联系人：</div>
                            <el-input v-model="form.full"></el-input>
                        </div>
                    </div>
                    <div class="biaodan-wrap">                        
                        <div class="biaodan">
                            <div class="baiodan-p">手机号：</div>
                            <el-input v-model="form.phone"></el-input>
                        </div>
                        <div class="biaodan">
                            <div class="baiodan-p">邮箱：</div>
                            <el-input v-model="form.email"></el-input>
                        </div>
                    </div>
                    <div class="biaodan-wrap">                        
                        <div class="biaodan">
                            <div class="baiodan-p">选择分类：</div>
                            <el-select v-model="value" placeholder="" @change="changeClass">
                                <el-option
                                v-for="item in classList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="biaodan">
                            <div class="baiodan-p">公司名称：</div>
                            <el-input v-model="form.company"></el-input>
                        </div>
                    </div>
                    <div class="biaodan-wrap">
                        <div class="biaodan da">
                            <div class="baiodan-p">产品简介：</div>
                            <div class="jianjie" ref="jianjie"></div>
                        </div>
                    </div>

                    <div class="tijiao" @click="tijiao">提交</div>
                    
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>    
    import E from 'wangeditor';
    import CONFIG from "../../../utils/config.js"
    import axios from 'axios'
    import {getcountrys,getplates,submit} from '../../../api/index';
    var editor;
    export default {
        data() {
            return {
                baseUrl:CONFIG.picture,
                token:window.localStorage.getItem('token'),
                classList: [],
                value: '',
                form:{
                    plate_id:'',
                    count_id:'',
                    name:'',
                    full:'',
                    phone:'',
                    email:'',
                    company:'',
                    content:'',                    
                },
            }
        },
        
        mounted() {
            editor = new E(this.$refs['jianjie']);            
            editor.config.menus = [
                'head',
                'bold',
                'fontSize',
                'fontName',
                'italic',
                'underline',
                // 'strikeThrough',
                // 'indent',
                'lineHeight',
                'foreColor',
                // 'backColor',
                // 'link',
                // 'list',
                // 'todo',
                'justify',
                // 'quote',
                // 'emoticon',
                'image',
                // 'video',
                // 'table',
                // 'code',
                // 'splitLine',
                'undo',
                'redo',
            ]
            //开启debug模式
            editor.config.debug = true;
            editor.config.showLinkImg = false;//关闭网络路径图片方式            
            editor.config.pasteFilterStyle = false;// 关闭粘贴内容中的样式            
            editor.config.pasteIgnoreImg = true;// 忽略粘贴内容中的图片            
            //editor.config.uploadImgShowBase64 = true;// 使用 base64 保存图片
            // 上传图片到服务器
            editor.config.uploadFileName = 'picture'; //设置文件上传的参数名称
            editor.config.uploadImgParams = {
                api_token: this.token,
            }
            editor.config.uploadImgServer = this.baseUrl; //设置上传文件的服务器路径
            editor.config.uploadImgMaxLength = 5; // 一次最多上传 5 个图片

            //自定义上传
            // this.editor.config.customUploadImg = (resultFiles, insertImgFn)=> {
            //     console.log(resultFiles)
            //     console.log(this.token)
            //     // resultFiles 是 input 中选中的文件列表
            //     // insertImgFn 是获取图片 url 后，插入到编辑器的方法
            //     const formData = new FormData()
            //     formData.append('picture', resultFiles[0]) // 要提交给后台的文件,并且字段的key为Filedata
            //     // console.log(formData.get('picture'));
            //     formData.append('api_token', this.token)
            //     let config = { // 添加请求头
            //         headers: { 'Content-Type': 'multipart/form-data' }
            //     };                
            //     axios.post(this.baseUrl, formData, config)
            //     .then(res => {
            //         console.log(res);
            //         // if (res.data.code==200) {
            //         //     // console.log("成功");
            //         //      this.$message.success(res.data.message)
            //         // }
            //     })

            //     // 上传图片，返回结果，将图片插入到编辑器中
            //     // insertImgFn(imgUrl)
            // }
            editor.create();            
        },
        created() {
            this.getplates()                     
        },
        methods: {   
            // 获取分类
            getplates(){
                getplates({
                    p_id:9,

                }).then((res)=>{
                    console.log(res)
                    if(res.code==200){
                        this.classList=res.data
                    }
                })
            },
            // 选择分类
            changeClass(val){
                // console.log(val)
                this.form.plate_id=val;
            },
            tijiao(){
                this.form.content=editor.txt.html()
                let reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //邮箱正则
                if (this.form.name.trim()=='') {
                    this.$message.error("请输入产品名称")
                    return;
                }
               if (this.form.full.trim()=='') {
                    this.$message.error("请输入联系人姓名")
                    return;
                }
                // if (this.form.email=='') {
                //     this.$message.error("请输入邮箱")
                //     return;
                // }
                // if(!reg.test(this.form.email)){ 
                //     this.$message.error("邮箱有误，请重填")
                //     return false; 
                // }
                if (this.form.phone=='') {
                    this.$message.error("请输入手机号")
                    return;
                }
                if(!(/^1[34578]\d{9}$/.test(this.form.phone))){ 
                    this.$message.error("手机号码有误，请重填")
                    return false; 
                } 
                if (this.form.plate_id=='') {
                    this.$message.error("请选择分类")
                    return;
                }
                if (this.form.content=='') {
                    this.$message.error("请输入产品简介")
                    return;
                }
                submit(this.form).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.$message.success(res.message)
                    }
                })
            },
        },
    }
</script>

<style lang="less" scoped>
/deep/ .el-select{display: block;}
/deep/ .el-cascader{width: 100%;}
/deep/ .el-input__inner{font-size: 17px;}
/deep/ .w-e-toolbar{z-index: 1001 !important;}
/deep/ .w-e-text-container{height: 400px !important;z-index: 1000 !important;}
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 110px;
                .details-text{color: #555555;font-size: 28px;margin-bottom: 40px;text-align: center;}
                .biaodan-wrap{
                    display: flex;align-items: center;justify-content: space-between;margin:25px 0;
                    .biaodan{
                        width: 45%;
                        .baiodan-p{
                            color: #555555;
                            font-size: 18px;
                            padding-bottom: 10px;
                        }
                    }
                    .da{width: 100%;}
                }
                .tijiao{
                    width: 175px;
                    height: 45px;
                    background: #0071DB;
                    text-align: center;
                    line-height: 45px;
                    color: #fff;
                    margin: 20px auto;
                    margin-top: 40px;
                    border-radius: 25px;
                    cursor: pointer;
                }
                
            }
        }
        
    }

</style>